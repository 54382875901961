/*--------------------------------------------------------------
#0.5  tp-footer
--------------------------------------------------------------*/
.tp-site-footer {
    background: #242424;
    position: relative;
    font-size: 15px;
    overflow: hidden;
    z-index: 1;

    .footer-shape1,
    .footer-shape2{
        position: absolute;
        left: 0;
        top: -50px;
        opacity: .2;
        z-index: -1;

        @media (max-width: 991px) {
            display: none;
        }

        .fi{
            &:before{
                color: #666;
                font-size: 200px;
            }
        }
    }

    .footer-shape2{
        left: auto;
        right: 0;
        top: auto;
        bottom:20px;
    }

    .logo{
        a{
            font-size: 50px;
            font-family: $heading-font-s3;
            font-weight: 600;
            display: flex;
            align-items: center;
            color: $white;
            line-height: 49px;
    
            @media(max-width:450px){
              font-size: 30px;
            }
    
            img{
                margin-right: 10px;
                @media(max-width:450px){
                 margin-right: 3px;
               }
            }
        }
    }

    ul {
        list-style: none;
    }

    p{
        color: $white;
    }
    li{
        color: $white;
    }

    .container {
        position: relative;
    }

    .tp-upper-footer{
        padding: 90px 0;
    }

    @media (max-width: 991px) {

        .tp-upper-footer{
            padding: 60px 0 0;
        }
    }
    
    @media (max-width: 767px) {
    
        .tp-upper-footer{
            padding: 60px 0 0;
        }
    }
    
    @media (max-width: 991px) {
    
        .tp-upper-footer .col{
            min-height: 235px;
            margin-bottom: 70px;
        }
    }
    
    @media (max-width: 767px) {
    
        .tp-upper-footer .col{
            min-height: auto;
            margin-bottom: 60px;
        }
    }

    .widget-title{
        margin-bottom: 30px;
    }

    @media (max-width: 767px) {

        .widget-title{
            margin-bottom: 20px;
        }
    }


    .widget-title{
        h3{
            font-size: 20px;
            color: $white;
            margin: 0;
            text-transform: uppercase;
            position: relative;
            font-family: $heading-font-s2;

            @media (max-width: 991px) {
                font-size: 20px;
                font-size: 1.25rem;
            }
        }
    }
    
    .about-widget {
        .logo {
            max-width: 180px;
        }
    }

    .about-widget {
        p{
            margin-bottom: 0.8em;
            line-height: 1.9em;

            &:last-child{
                margin-bottom: 0;
            }
        }

        ul{
            overflow: hidden;
            padding-top: 10px;

            li{
                font-size: 22px;
                float: left;

                a{
                    color: $white;
                    width: 36px;
                    height: 36px;
                    line-height: 40px;
                    background: rgba($color: $white, $alpha: 0.1);
                    display: block;
                    text-align: center;
                    @include rounded-border(50%);
                    font-size: 18px;

                    &:hover{
                        color:$dark-gray;
                        background: $white;
                    }
                }
            }

            li+li{
                margin-left: 25px;
                @media (max-width: 1199px) {
                    margin-left: 15px;
                }
            }
        }
    }
    .tp-service-link-widget{
        padding-left: 70px;

        @media (max-width: 1199px) {
            padding-left: 0px;
        }
    }
        
    .link-widget {
        overflow: hidden;
        

        @media screen and (min-width: 1200px) {
            padding-left: 75px;
        }
        
        @media (max-width: 1199px) {
            padding-left: 20px;
        }
        
        @media (max-width: 991px) {
            padding-left: 0;
        }
        
        @media (max-width: 767px) {
            max-width: 350px;
        }

        ul{
            li{
                position: relative;
                a{
                    color: $white;
                    font-size: 16px;

                    &:hover{
                        text-decoration: underline;
                    }
                }
            }
            li+li{
              padding-top: 15px;
            }
        }
    }


    .contact-ft {
        margin-top: 20px;

        ul {
            li {
                padding-bottom: 15px;
                position: relative;
                padding-left: 35px;
                color: #e5e3e3;
                font-size: 16px;

                i{
                    position: absolute;
                    left: 0;
                    top: -3px;
                }

                .fi:before{
                    font-size: 20px;
                    margin-right: 15px;
                }
            }
        }
    }
    
    

    .tp-lower-footer{
        text-align: center;
        position: relative;
        background: #1e1c1c;
    
        .row{
            padding: 20px 0;
            position: relative;
        }
    
        .copyright{
            display: inline-block;
            font-size: 15px;
            font-size: 0.9375rem;
            margin: 0;
    
            a{
                color: $white;
                text-decoration: none;
            }
    
            @media (max-width: 991px) {
                float: none;
                display: block;
            }
        }
    }
    

    .newsletter-widget {
        form {
            margin-top: 25px;
            position: relative;

            input {
                background-color: $white;
                height: 50px;
                color: $text-color;
                padding: 6px 20px;
                border-radius: 5px;
                border: none;
                box-shadow: none;
            }

            .submit {
                position: absolute;
                right: 20px;
                top: 55%;
                @include translatingY();

                button {
                    background: transparent;
                    border: 0;
                    outline: 0;
                    font-size: 20px;
                    color: $theme-primary-color;
                    margin-top: -2px;
                    position: relative;
                    right: -5px;
                }
            }
        }
    }
}






