.mobileMenu {
    position: fixed;
    left: -270px;
    top: 0;
    z-index: 9999;
    height: 100vh;
    width: 270px;
    background: #fff;
    transition: all 0.3s ease 0s;
    box-shadow: 0px 10px 40px 0px rgba(50, 50, 50, 0.1);
    overflow-x: auto;
    overflow-y: scroll;
}

.mobileMenu::-webkit-scrollbar {
    width: 4px;
}

.mobileMenu::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

.mobileMenu::-webkit-scrollbar-thumb {
    background-color: darkgrey;
}

.responsivemenu .card-body {
    background: #fff;
}

.mobileMenu h2 {
    padding-left: 20px;
}

.mobileMenu.show {
    left: 0;
}

.showmenu {
    position: absolute;
    right: -30px;
    top: 0;
    z-index: 999;
    cursor: pointer;
}

.responsivemenu {
    list-style: none;
    padding-left: 0;
    padding-top: 80px;
}

.responsivemenu li a,
.responsivemenu li p {
    display: block;
    padding: 13px 20px;
    font-size: 16px;
    letter-spacing: 0;
    text-transform: capitalize;
    font-weight: 500;
    color: #666;
    cursor: pointer;
    font-family: 'Roboto', sans-serif;
}

.responsivemenu li p {
    position: relative;
    margin-bottom: 0;
}

.responsivemenu .card {
    border: none
}

.responsivemenu .card-body {
    padding-top: 0;
}

.responsivemenu .card-body li {
    background: #fff;
}

.responsivemenu li a {
    position: relative;
}

.responsivemenu li i {
    position: absolute;
    right: 20px;
    top: 17px;
}

.showmenu i {
    font-size: 30px;
    color: #fff;
}

.mobileMenu {
    display: none;
}

.showmenu {
    display: none;
}

@media (max-width:992px) {
    .showmenu {
        display: block;
        width: 40px;
        height: 36px;
        text-align: center;
        background-color: #02a1e3;
        line-height: 50px;
        border-radius: 5px;
    }

    .showmenu button {
        background: none !important;
        text-align: center;
    }

    .showmenu button:focus {
        outline: none;
        box-shadow: none;
    }

    .showmenu button span {
        background-color: #fff;
        width: 20px;
        display: block;
        height: 2px;
        margin: 0 auto;
        margin-bottom: 5px;
    }

    .mobileMenu {
        display: block;
    }
}

@media(max-width:575px) {
    .showmenu {
        right: -50px;
        top: 2px;
    }
}


.middle-header-3 .showmenu {
    top: 36px;
}

.middle-header2 .showmenu {
    top: 26px;
}

.middle-header-3 .showmenu i {
    color: #fff;
}


@media(max-width:450px) {
    .mobileMenu {
        left: -240px;
        width: 240px;
    }

    .responsivemenu li a,
    .responsivemenu li p {
        font-size: 14px;
    }
}