	/*
  	Flaticon icon font: Flaticon
  	Creation date: 28/12/2021 04:51
  	*/

@font-face {
  font-family: "Flaticon";
    src: url("../fonts/flaticon.ttf?6b10d6f8d5ccbf1360e338d5dc5fb538") format("truetype"),
    url("../fonts/flaticon.woff?6b10d6f8d5ccbf1360e338d5dc5fb538") format("woff"),
    url("../fonts/flaticon.woff2?6b10d6f8d5ccbf1360e338d5dc5fb538") format("woff2"),
    url("../fonts/flaticon.eot?6b10d6f8d5ccbf1360e338d5dc5fb538#iefix") format("embedded-opentype"),
    url("../fonts/flaticon.svg?6b10d6f8d5ccbf1360e338d5dc5fb538#flaticon") format("svg");
  font-weight: normal;
  font-style: normal;
}

@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: "Flaticon";
    src: url("../fonts/flaticon.svg") format("svg");
  }
}

[class^="flaticon-"]:before, [class*=" flaticon-"]:before,
  [class^="flaticon-"]:after, [class*=" flaticon-"]:after {   
    font-family: Flaticon;
    font-size: 50px;
    font-style: normal;
  }

  .flaticon-bag:before {
    content: "\f101";
}
.flaticon-shopping-cart:before {
    content: "\f102";
}
.flaticon-loupe:before {
    content: "\f103";
}
.flaticon-heart:before {
    content: "\f104";
}
.flaticon-like:before {
    content: "\f105";
}
.flaticon-envelope:before {
    content: "\f106";
}
.flaticon-pin:before {
    content: "\f107";
}
.flaticon-call:before {
    content: "\f108";
}
.flaticon-mobile-phone:before {
    content: "\f109";
}
.flaticon-user:before {
    content: "\f10a";
}
.flaticon-delete:before {
    content: "\f10b";
}
.flaticon-x-mark:before {
    content: "\f10c";
}
.flaticon-cancel:before {
    content: "\f10d";
}
.flaticon-menu:before {
    content: "\f10e";
}
.flaticon-play-button:before {
    content: "\f10f";
}
.flaticon-play:before {
    content: "\f110";
}
.flaticon-right-arrow:before {
    content: "\f111";
}
.flaticon-left-arrow:before {
    content: "\f112";
}
.flaticon-left-quote:before {
    content: "\f113";
}
.flaticon-right-quote:before {
    content: "\f114";
}
.flaticon-delivery:before {
    content: "\f115";
}
.flaticon-credit-card:before {
    content: "\f116";
}
.flaticon-support:before {
    content: "\f117";
}
.flaticon-honey:before {
    content: "\f118";
}
.flaticon-honey-1:before {
    content: "\f119";
}
.flaticon-honeycomb:before {
    content: "\f11a";
}
.flaticon-honey-2:before {
    content: "\f11b";
}
.flaticon-bee-2:before {
    content: "\f11c";
}
.flaticon-honey-3:before {
    content: "\f11d";
}
