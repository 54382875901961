.theme-btn,
.theme-btn-s2 {
	background: $theme-primary-color;
	color: $white;
	font-size: 18px;
	font-weight: 500;
	display: inline-block;
	padding: 15px 40px 12px 30px;
	border: 0;
	@include rounded-border(0);
	text-transform: uppercase;
	transition: all .3s;

	i {
		margin-left: 10px;
	}

	&:hover,
	&:focus,
	&:active {
		background: darken($theme-primary-color, 6%);
		color: $white;
	}

	@include media-query(767px) {
		padding: 12px;
		font-size: 13px;
	}
}

.theme-btn-s2 {
	background: transparent;
	color: $dark-gray;
	border: 2px solid #eefbfd;
	padding: 15px 20px 12px 20px;

	i {
		color: $theme-primary-color;
	}

	&:hover,
	&:focus,
	&:active {
		background: $theme-primary-color;
		color: $white;

		i {
			color: $white;
		}

	}
}

.view-cart-btn {
	background-color: $small-black;
	height: 45px;
	text-align: center;
	display: inline-block;
	width: 120px;
	line-height: 45px;
	color: $white;

	&:hover {
		color: $white;
	}
}