.invoice-header {
    background-color: rgba(2, 161, 227, 0.1);
    padding: 20px;
    border-bottom: 2px solid #ddd;
}

.invoice-title {
    color: #23b7e5;
}

.table thead {
    background-color: #02a1e3;
    color: white;
}

.total {
    font-size: 20px;
    font-weight: bold;
    color: #02a1e3;
}

.bg-light-blue {
    background-color: #02a1e3 !important;
}

.img-holder {
    margin-bottom: 25px;
}

.img-holder img {
    border-radius: 50%;
    width: 100%;
    max-width: 200px;
}